import { Component } from '@angular/core';
import { ProductBasePage } from 'src/app/pages/base/product-base-page';

@Component({
    selector: 'app-info-bar',
    templateUrl: './info-bar.component.html',
    styleUrls: ['./info-bar.component.scss']
})
export class InfoBarComponent extends ProductBasePage {
}
