import { ActionReducerMap } from '@ngrx/store';
import { Brand, Category, Product } from 'src/app/app.models';
import { CompanyConfigDto, ShopProductDto } from 'src/api/gbcar/models';
import { Currency } from 'src/app/config/application.config';
import { IAppState } from '../app.state';
import { REDUX_CONTEXT_ACTION } from 'src/app/config/redux-config';
import * as contextActions from '../actions/context.actions';

export function brandsReducer(state: Array<Brand>, action: contextActions.SetBrandsAction): Array<Brand> {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_BRANDS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function categoriesReducer(state: Array<Category>, action: contextActions.SetCategoriesAction): Array<Category> {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_CATEGORIES: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function companyConfigReducer(state: CompanyConfigDto = null, action: contextActions.SetCompanyConfigAction): CompanyConfigDto {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_COMPANY_CONFIG: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function genericSearchQueryTextReducer(state: string, action: contextActions.SetGenericSearchQueryTextAction): string {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_GENERIC_SEARCH_QUERY_TEXT: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function genericSearchQueryCtegoryReducer(state: number, action: contextActions.SetGenericSearchQueryCategoryAction): number {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_GENERIC_SEARCH_QUERY_CATEGORY: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function initializedReducer(state: boolean = false, action: contextActions.SetInitializedAction): boolean {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_APPLICATION_INITIALIZED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function productBestsellerReducer(state: Array<ShopProductDto> = null, action: contextActions.SetProductBestsellersAction): Array<ShopProductDto> {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_PRODUCTBESTSELLERS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function productNewsReducer(state: Array<ShopProductDto> = null, action: contextActions.SetProductNewsAction): Array<ShopProductDto> {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_PRODUCTNEWS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

// export function selectedProductReducer(state: Product = null, action: contextActions.SetSelectedProductAction): Product {

//     switch (action.type) {

//         case REDUX_CONTEXT_ACTION.SET_SELECTEDPRODUCT: {
//             return action.payload;
//         }
//         default: {
//             return state;
//         }
//     }
// }

// export function selectedProductLinksReducer(state: Array<Product> = null, action: contextActions.SetSelectedProductLinksAction): Array<Product> {

//     switch (action.type) {

//         case REDUX_CONTEXT_ACTION.SET_SELECTEDPRODUCT_LINKS: {
//             return action.payload;
//         }
//         default: {
//             return state;
//         }
//     }
// }

export function currencyIdReducer(state: number = Currency.DEFAULT, action: contextActions.SetCurrencyIdAction): number {

    switch (action.type) {

        case REDUX_CONTEXT_ACTION.SET_CURRENCYID: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export const appStateReducers: ActionReducerMap<IAppState> = {

    // Context
    initializedState: initializedReducer,
    genericSearchQueryTextState: genericSearchQueryTextReducer,
    genericSearchQueryCategoryState: genericSearchQueryCtegoryReducer,
    productBestsellersState: productBestsellerReducer,
    brandsState: brandsReducer,
    categoriesState: categoriesReducer,
    companyConfigState: companyConfigReducer,
    productNewsState: productNewsReducer,
    // selectedProductState: selectedProductReducer,
    // selectedProductLinksState: selectedProductLinksReducer,
    currencyIdState: currencyIdReducer,
};
