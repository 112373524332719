import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Flag'
})
export class FlagPipe implements PipeTransform {

  constructor() {
  }

  transform(countryCode: string) {
    var result: string;

    if (countryCode) {
      result = `assets/images/flags/${countryCode.toLowerCase()}.svg`;
    }

    return result;
  }
}
