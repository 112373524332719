import { AppService } from '../app.service';
import { AppRouteLink } from '../config/route-config';
import { AuthenticationService } from './authentication.service';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ExternalAuthenticationService } from 'src/api/gbcar/services';
import { Injectable } from '@angular/core';
import { LimitedLoginResponseDto } from 'src/api/gbcar/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationGuardService implements CanActivate {

    constructor(
        private readonly appService: AppService,
        private readonly authenticationService: AuthenticationService,
        private readonly externalAuthenticationService: ExternalAuthenticationService,
        private readonly router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (state.url.startsWith('/' + AppRouteLink.PASSWORD_SET_ID)) {

            if ( route?.params['token'] ) {

                return new Observable<boolean>(observer => {

                    const token = route?.params['token'];
                    this.externalAuthenticationService.postExternalAuthenticationLimitedLogin({ token }).subscribe({
                        next: (response: LimitedLoginResponseDto) => {

                            if (response?.errorMessage) {

                                console.log(response?.errorMessage);
                                         
                                // Ezen a ponton még nem tud felépülni a MatDialog, ezért a login komponensben kerül megnyitásra.
                                this.appService.tempError = 'Jelszóváltás link érvénytelen. Sajnáljuk, hogy a jelszóváltás nem sikerült. Kérjük, vegye fel velünk a kapcsolatot segítségért.';
                                this.router.navigate([AppRouteLink.LOGIN]);

                            } else if (!response?.errorMessage && response?.token && response?.uerName) {

                                this.authenticationService.resetUerName = response.uerName;
                                this.authenticationService.authorizationCompanyToken = response.token;
                                observer.next(true);

                            } else {
                                observer.next(false);
                                this.router.navigate([AppRouteLink.LOGIN]);

                            }
                        },
                        error: () => {
                            observer.next(false);
                            this.router.navigate([AppRouteLink.LOGIN]);

                        },
                        complete: () => { }
                    });
                });

            } else {
                return new Observable<boolean>(observer => {
                    observer.next(false);
                });

            }
        } else

            if (!this.authenticationService.isCompanyAuthenticated && route.routeConfig.path === AppRouteLink.ACCOUNT_ID) {

                return new Observable<boolean>(observer => {

                    observer.next(false);
                    this.authenticationService.signOut();
                });
            } else {
                return true;
            }
    }
}
