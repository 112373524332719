import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    setLocalStorage(key: string, data: any): void {

        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.error(`Error saving ${key} to localStorage`, e);
        }
    }

    getLocalStorage<T>(key: string): T {

        try {
            return JSON.parse(localStorage.getItem(key)) as T;
        } catch (e) {
            return null;
        }
    }

    removeLocalStorage(key: string) {
        localStorage.removeItem(key);
    }
}
