/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class CodeTableEntityService extends __BaseService {
  static readonly getApiCodeTableEntityIdPath = '/api/CodeTableEntity/Id';
  static readonly getApiCodeTableEntityChildEntityIdsPath = '/api/CodeTableEntity/ChildEntityIds';
  static readonly getApiCodeTableEntityEntityIdsByRefCodeTableIdPath = '/api/CodeTableEntity/EntityIdsByRefCodeTableId';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CodeTableEntityService.GetApiCodeTableEntityIdParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `codeTableId`:
   *
   * @return Success
   */
  getApiCodeTableEntityIdResponse(params: CodeTableEntityService.GetApiCodeTableEntityIdParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.codeTableId != null) __params = __params.set('codeTableId', params.codeTableId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CodeTableEntity/Id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `CodeTableEntityService.GetApiCodeTableEntityIdParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `codeTableId`:
   *
   * @return Success
   */
  getApiCodeTableEntityId(params: CodeTableEntityService.GetApiCodeTableEntityIdParams): __Observable<number> {
    return this.getApiCodeTableEntityIdResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `CodeTableEntityService.GetApiCodeTableEntityChildEntityIdsParams` containing the following parameters:
   *
   * - `codeTableId`:
   *
   * - `parentId`:
   *
   * @return Success
   */
  getApiCodeTableEntityChildEntityIdsResponse(params: CodeTableEntityService.GetApiCodeTableEntityChildEntityIdsParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.codeTableId != null) __params = __params.set('codeTableId', params.codeTableId.toString());
    if (params.parentId != null) __params = __params.set('parentId', params.parentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CodeTableEntity/ChildEntityIds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * @param params The `CodeTableEntityService.GetApiCodeTableEntityChildEntityIdsParams` containing the following parameters:
   *
   * - `codeTableId`:
   *
   * - `parentId`:
   *
   * @return Success
   */
  getApiCodeTableEntityChildEntityIds(params: CodeTableEntityService.GetApiCodeTableEntityChildEntityIdsParams): __Observable<Array<number>> {
    return this.getApiCodeTableEntityChildEntityIdsResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * @param params The `CodeTableEntityService.GetApiCodeTableEntityEntityIdsByRefCodeTableIdParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `codeTableId`:
   *
   * - `refCodeTableId`:
   *
   * @return Success
   */
  getApiCodeTableEntityEntityIdsByRefCodeTableIdResponse(params: CodeTableEntityService.GetApiCodeTableEntityEntityIdsByRefCodeTableIdParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.codeTableId != null) __params = __params.set('codeTableId', params.codeTableId.toString());
    if (params.refCodeTableId != null) __params = __params.set('refCodeTableId', params.refCodeTableId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CodeTableEntity/EntityIdsByRefCodeTableId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * @param params The `CodeTableEntityService.GetApiCodeTableEntityEntityIdsByRefCodeTableIdParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `codeTableId`:
   *
   * - `refCodeTableId`:
   *
   * @return Success
   */
  getApiCodeTableEntityEntityIdsByRefCodeTableId(params: CodeTableEntityService.GetApiCodeTableEntityEntityIdsByRefCodeTableIdParams): __Observable<Array<number>> {
    return this.getApiCodeTableEntityEntityIdsByRefCodeTableIdResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }
}

module CodeTableEntityService {

  /**
   * Parameters for getApiCodeTableEntityId
   */
  export interface GetApiCodeTableEntityIdParams {
    entityId?: number;
    codeTableId?: number;
  }

  /**
   * Parameters for getApiCodeTableEntityChildEntityIds
   */
  export interface GetApiCodeTableEntityChildEntityIdsParams {
    codeTableId?: number;
    parentId?: number;
  }

  /**
   * Parameters for getApiCodeTableEntityEntityIdsByRefCodeTableId
   */
  export interface GetApiCodeTableEntityEntityIdsByRefCodeTableIdParams {
    entityId?: number;
    codeTableId?: number;
    refCodeTableId?: number;
  }
}

export { CodeTableEntityService }
