<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div *ngIf="product?.availibilityCount > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">Mennyiség:</span> 
            <button mat-icon-button matTooltip="Remove" (click)="decrement()"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="Add" (click)="increment()"><mat-icon>add</mat-icon></button>
        </bdi>
    </div>
    <div *ngIf="type!='wish'">
        <!-- <button fxShow="false" fxShow.gt-sm mat-icon-button matTooltip="Hozzáadás a kívánságlitához" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button> -->
        <button mat-icon-button 
                matTooltip="Kosárba teszem" 
                *ngIf="product?.availibilityCount > 0" (click)="addToCart(product)">

            <img class="utils-cart-img" src="assets/images/utils/shopping-cart-dark.svg">
        </button>
        <!-- <button fxShow="false" fxShow.gt-sm mat-icon-button matTooltip="Összehasonlításra jelölöm" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button> -->        
    </div>
</div> 
<div *ngIf="product?.availibilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
    Sorry, this item is unavailable. Please choose a different one.
</div>