/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PlaceSuggestionDto } from '../models/place-suggestion-dto';
import { GetPlaceSuggestionsRequest } from '../models/get-place-suggestions-request';
import { VenueDto } from '../models/venue-dto';
import { GetVenueRequest } from '../models/get-venue-request';
@Injectable({
  providedIn: 'root',
})
class VenueService extends __BaseService {
  static readonly postApiVenueGetPlaceSuggestionsPath = '/api/Venue/GetPlaceSuggestions';
  static readonly postApiVenueGetVenuePath = '/api/Venue/GetVenue';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiVenueGetPlaceSuggestionsResponse(body?: GetPlaceSuggestionsRequest): __Observable<__StrictHttpResponse<Array<PlaceSuggestionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Venue/GetPlaceSuggestions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PlaceSuggestionDto>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiVenueGetPlaceSuggestions(body?: GetPlaceSuggestionsRequest): __Observable<Array<PlaceSuggestionDto>> {
    return this.postApiVenueGetPlaceSuggestionsResponse(body).pipe(
      __map(_r => _r.body as Array<PlaceSuggestionDto>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiVenueGetVenueResponse(body?: GetVenueRequest): __Observable<__StrictHttpResponse<VenueDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Venue/GetVenue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VenueDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiVenueGetVenue(body?: GetVenueRequest): __Observable<VenueDto> {
    return this.postApiVenueGetVenueResponse(body).pipe(
      __map(_r => _r.body as VenueDto)
    );
  }
}

module VenueService {
}

export { VenueService }
