/* const myObserver = new ResizeObserver(entries => {
 // this will get called whenever div dimension changes
  entries.forEach(entry => {
    console.log('width', entry.contentRect.width);
    console.log('height', entry.contentRect.height);
  });

});

const someEl = document.querySelector('.some-element');

// start listening to changes
myObserver.observe(someEl);

// later, stop listening to changes
myObserver.disconnect(); */

import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[liSizeChange]',
})
export class SizeChangeDirective implements OnDestroy, OnInit {
  private changes: ResizeObserver;
  private delaySubject: Subject<ResizeObserverEntry[]> = new Subject();
  private subscriptions = new Subscription();
  @Output()
  public sizeChange = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement as HTMLElement;

    this.changes = new ResizeObserver((entries) => {
      this.delaySubject.next(entries);
    });

    this.changes.observe(element);
  }
  ngOnInit(): void {
    this.subscriptions.add(
      this.delaySubject.pipe(debounceTime(200)).subscribe((res) => {
        this.sizeChange.emit(res);
      })
    );
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    this.subscriptions.unsubscribe();
  }
}
