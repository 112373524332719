<div fxLayout="row wrap" style="padding: .7rem 1.5rem;">
    <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50">
        <div class="h-full m-0" fxLayout="row"  fxLayoutAlign="start center">
            <img class="utils-ico-img-box" src="assets/images/utils/box.svg">
            <div class="content pr-1">
                <p>Áruk raktáron</p>
            </div> 
        </div> 
    </div>
    <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50"> 
        <div class="h-full m-0" fxLayout="row"  fxLayoutAlign="start center">
            <img class="utils-ico-img" src="assets/images/utils/clock.svg">
            <div class="content pr-1">
                <p>25 órán belül elküldjük</p>
            </div> 
        </div>
    </div>
    <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50"> 
        <div class="h-full m-0" fxLayout="row"  fxLayoutAlign="start center">
            <img class="utils-ico-img" src="assets/images/utils/badge.svg">
            <div class="content pr-1">
                <p>Hűségprogram</p>
            </div> 
        </div>
    </div>

    <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50"> 
        <div class="h-full m-0" fxLayout="row"  fxLayoutAlign="start center">
            <img class="utils-ico-img-truck" src="assets/images/utils/truck.svg">
            <div class="content">
                <p>Ingyenes szállítás {{office?.freeDeliveryLimit | number : '.0-0' | currencySign: currencyId}}-tól</p>
            </div> 
        </div>
    </div>
</div>