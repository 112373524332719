import { Action } from '@ngrx/store';
import { Brand, Category, Product } from 'src/app/app.models';
import { CompanyConfigDto, ShopProductDto } from 'src/api/gbcar/models';
import { REDUX_CONTEXT_ACTION } from "src/app/config/redux-config";

// Context
export class SetInitializedAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_APPLICATION_INITIALIZED;

    constructor(public payload: boolean) { }
}

export class SetProductBestsellersAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_PRODUCTBESTSELLERS;

    constructor(public payload: Array<ShopProductDto>) { }
}


export class SetBrandsAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_BRANDS;

    constructor(public payload: Array<Brand>) { }
}

export class SetCategoriesAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_CATEGORIES;

    constructor(public payload: Array<Category>) { }
}

export class SetCompanyConfigAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_COMPANY_CONFIG;

    constructor(public payload: CompanyConfigDto) { }
}

export class SetGenericSearchQueryTextAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_GENERIC_SEARCH_QUERY_TEXT;

    constructor(public payload: string) { }
}

export class SetGenericSearchQueryCategoryAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_GENERIC_SEARCH_QUERY_CATEGORY;

    constructor(public payload: number) { }
}

export class SetProductNewsAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_PRODUCTNEWS;

    constructor(public payload: Array<ShopProductDto>) { }
}

// export class SetSelectedProductAction implements Action {
//     public readonly type = REDUX_CONTEXT_ACTION.SET_SELECTEDPRODUCT;

//     constructor(public payload: Product) { }
// }

// export class SetSelectedProductLinksAction implements Action {
//     public readonly type = REDUX_CONTEXT_ACTION.SET_SELECTEDPRODUCT_LINKS;

//     constructor(public payload: Array<Product>) { }
// }

export class SetCurrencyIdAction implements Action {
    public readonly type = REDUX_CONTEXT_ACTION.SET_CURRENCYID;

    constructor(public payload: number) { }
}
