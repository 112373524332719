export class RoutePrams {
    public static readonly TRANSACTION_ID = 'trn';
}

export class ProdcutCategory {
    public static readonly ALL_CATEGORIES: number = 0;
}

export class ActionConfig {
    public static readonly SET_GENERIC_SEARCH_QUERY_CATEGORY = 'SET_GENERIC_SEARCH_QUERY_CATEGORY';
}

export class Currency {
    public static readonly EUR: number = 1;
    public static readonly USD: number = 2;
    public static readonly HUF: number = 3;
    public static readonly DEFAULT: number = Currency.HUF;
}

export class ErrorCode
{
    public static readonly ORDER_IS_INVALID = 1000;
    public static readonly PRICING_NOT_FOUND = 1001;
    public static readonly PARTNER_COMPANY_NOT_ACTIVE = 1002;
    public static readonly PARTNER_COMPANY_DELETED = 1003;
    public static readonly PRODCUT_PRICE_CHANGED = 2000;    
}

export class GridPageConfig {
    public static readonly DEFAULT_ROW_COUNT: number = 15;
    public static readonly DEFAULT_MAX_ROW_COUNT: number = 10000000;
}

export class GenericTableConfig {
    public static readonly PRODUCT_PAGE = 'vProduct';
}

export class LocalStorage {
    public static readonly GBCAR_COMPANY = 'gbcar_company';
    public static readonly COMPANY_TOKEN = 'gbcar_company_token';
    public static readonly CUSTOMER_TOKEN = 'gbcar_customer_token';
}

export class LoginPanelType {
    public static readonly Login = 0;
    public static readonly ForgotPassword = 1;
    public static readonly EmailSent = 2;
}

export class PriceTypeId
{
    public static readonly  Sales: number = 1;
    public static readonly  Retail: number = 2;
    public static readonly  Wholesale: number = 3;
}

export class PaymentTypeId {
    public static readonly BankCard: number = 1;
    public static readonly Transfer: number = 2;
    public static readonly Cash: number = 3;
    public static readonly PersonalDelivery: number = 4;
}

export class PaymentStatusTypeId {
    public static readonly New: number = 1;
    public static readonly Canceled: number = 2;
    public static readonly Processing: number = 3;
    public static readonly Completed: number = 4;
    public static readonly Rejected: number = 5;
    public static readonly Closed: number = 6;
    public static readonly Error: number = 7;
    public static readonly PendingApproval: number = 8;
    public static readonly Timeout: number = 9;    
}

export class SmartDialogTypeId {
    public static readonly Error: number = 1;
    public static readonly Waring: number = 2;
    public static readonly Information: number = 3;
}

export class SortType {
    public static readonly Default: number = 1;
    public static readonly LowToHight: number = 2;
    public static readonly HighToLow: number = 3;
}

export class Validation {
    public static readonly REGEX_ANY_6: RegExp = /^.{6,}$/;
    public static readonly REGEX_HAS_NUMBER: RegExp = /^.*[0-9].*$/;
    public static readonly REGEX_HAS_CAPITAL: RegExp = /^.*[A-Z].*$/;
    public static readonly REGEX_HAS_LOWERCASE: RegExp = /^.*[a-z].*$/;
}
