import { BrandImageResolverPipe } from './brand-image-resolver.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { CommonModule } from '@angular/common';
import { CurrencySignPipe } from './currency-sign.pipe';
import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { FlagPipe } from './flag-resolver.pipe';
import { ImageResolverPipe } from './image-resolver.pipe';
import { NgModule } from '@angular/core';
import { PhoneNumberPipe } from './phone-number.pipe';
import { PhoneRefPipe } from './phone-ref.pipe';
import { ProductBigImageResolverPipe } from './product-big-image-resolver.pipe';
import { ProductImageResolverPipe } from './product-image-resolver.pipe';
import { ProductSmallImageResolverPipe } from './product-small-image-resolver.pipe';
import { ProfilePicturePipe } from './profilePicture.pipe';
import { UserSearchPipe } from './user-search.pipe';

@NgModule({
    imports: [ 
        CommonModule 
    ],
    declarations: [
        BrandImageResolverPipe,
        BrandSearchPipe,
        CurrencySignPipe,
        FlagPipe,
        FilterByIdPipe,
        FilterBrandsPipe,
        ImageResolverPipe,
        PhoneNumberPipe,
        PhoneRefPipe,
        ProductBigImageResolverPipe,
        ProductImageResolverPipe,
        ProductSmallImageResolverPipe,
        ProfilePicturePipe,
        UserSearchPipe,
    ],
    exports: [
        BrandImageResolverPipe,
        BrandSearchPipe,
        CurrencySignPipe,
        FlagPipe,
        FilterByIdPipe,
        FilterBrandsPipe,
        ImageResolverPipe,
        PhoneNumberPipe,
        PhoneRefPipe,
        ProductImageResolverPipe,
        ProductBigImageResolverPipe,
        ProductSmallImageResolverPipe,
        ProfilePicturePipe,
        UserSearchPipe,
    ]
})
export class PipesModule { }
