/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetVisitorsResponse } from '../models/get-visitors-response';
import { PageableRequest } from '../models/pageable-request';
import { FileResponseBase } from '../models/file-response-base';
import { VisitorDto } from '../models/visitor-dto';
@Injectable({
  providedIn: 'root',
})
class VisitorService extends __BaseService {
  static readonly postApiVisitorListPath = '/api/Visitor/List';
  static readonly getApiVisitorExportAllPath = '/api/Visitor/ExportAll';
  static readonly putApiVisitorPath = '/api/Visitor';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiVisitorListResponse(body?: PageableRequest): __Observable<__StrictHttpResponse<GetVisitorsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Visitor/List`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetVisitorsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiVisitorList(body?: PageableRequest): __Observable<GetVisitorsResponse> {
    return this.postApiVisitorListResponse(body).pipe(
      __map(_r => _r.body as GetVisitorsResponse)
    );
  }

  /**
   * @return Success
   */
  getApiVisitorExportAllResponse(): __Observable<__StrictHttpResponse<FileResponseBase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visitor/ExportAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileResponseBase>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiVisitorExportAll(): __Observable<FileResponseBase> {
    return this.getApiVisitorExportAllResponse().pipe(
      __map(_r => _r.body as FileResponseBase)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  putApiVisitorResponse(body?: VisitorDto): __Observable<__StrictHttpResponse<VisitorDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Visitor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitorDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  putApiVisitor(body?: VisitorDto): __Observable<VisitorDto> {
    return this.putApiVisitorResponse(body).pipe(
      __map(_r => _r.body as VisitorDto)
    );
  }
}

module VisitorService {
}

export { VisitorService }
