import { BasePage } from "./base-page";
import { Category, Product } from "src/app/app.models";
import { CompanyService, ShopProductBrandService, ShopProductCompanyService, ShopProductService } from "src/api/gbcar/services";
import { CompanyConfigDto, GetProductGroupTypesResponse, GetProductsResponse, GetShopProductBrandsResponse, GetShopProductsCompanyRequest, PageableRequest } from "src/api/gbcar/models";
import { Directive, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { MapperService } from "src/app/services/mapper.service";
import { ServiceLocator } from "src/app/services/locator.service";
import { SortItem } from "src/app/shared/interfaces/short-item.interface";
import { SortType } from "src/app/config/application.config";
import * as appStateSelectors from '../../redux/app.state';
import * as contextActions from '../../redux/actions/context.actions';

@Directive()
export class ProductBasePage extends BasePage implements OnInit, OnDestroy {

    public counts = [16, 32, 48, 64];
    public count: any;
    public currencyId: number;
    public defaultCount = 1;
    public page: any;
    public products: Array<Product> = [];
    public sort: SortItem;
    public sortings = [
        { id: SortType.Default, name: 'Alapértelmezett rendezés' },
        { id: SortType.LowToHight, name: 'Olcsóbbak' },
        { id: SortType.HighToLow, name: 'Drágábbak' },
    ];
    public viewColDefault: number = 25;
    public viewCol = this.viewColDefault;
    public viewTypeDefault: string = 'grid';
    public viewType = this.viewTypeDefault;

    protected companyService: CompanyService;
    protected mapperService: MapperService;
    protected shopProductBrandService: ShopProductBrandService;
    protected shopProductCompanyService: ShopProductCompanyService;
    protected shopProductService: ShopProductService;
    protected filterPriceType: string;

    constructor() {
        super();

        this.companyService = ServiceLocator.injector.get(CompanyService);
        this.mapperService = ServiceLocator.injector.get(MapperService);
        this.shopProductBrandService = ServiceLocator.injector.get(ShopProductBrandService);
        this.shopProductCompanyService = ServiceLocator.injector.get(ShopProductCompanyService);
        this.shopProductService = ServiceLocator.injector.get(ShopProductService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.count = this.counts[this.defaultCount];

        this.subscriptions.push(
            this.appState.select(appStateSelectors.currencyIdSelector).subscribe((currencyId: number) => {
                this.currencyId = currencyId;
            }),
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected getBrands() {

        const statetValue = this.state.getValue();
        if (!statetValue.brandsState) {

            console.log(`-- Base getBrands`);
            this.setBrands();
        }
    }

    protected setBrands() {

        this.isLoading = true;
        this.shopProductBrandService.getApiShopProductBrand().subscribe({
            next: (response: GetShopProductBrandsResponse) => {
                let brands = response?.productBrands ? response.productBrands.map(b => this.mapperService.toBrand(b)) : null;

                console.log(`-- Base getBrands ready`);
                // Brand-ok tárolása a reactive storage-ba.
                this.appState.dispatch(new contextActions.SetBrandsAction(brands));
            },
            error: (e) => {
                console.error(e);
                this.isLoading = false;
                //this.toastError('Get brands failed.');
            },
            complete: () => this.isLoading = false
        });
    }

    protected getCategories() {

        const statetValue = this.state.getValue();
        if (!statetValue.categoriesState) {
            console.log(`-- Base getCategories`);
            this.setCategories();
        }
    }

    protected setCategories() {

        this.isLoading = true;
        this.shopProductService.getApiShopProductGroupTypes().subscribe({
            next: (response: GetProductGroupTypesResponse) => {

                if (response?.productGroupTypes?.length) {
                    const productGroupTypes = response.productGroupTypes.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1);
                    let categories = productGroupTypes.map(c => this.mapperService.toCategory(c));
                    categories = [...[{ "id": 0, "name": "Minden kategória", "hasSubCategory": false, "parentId": 0 } as Category], ...categories];

                    console.log(`-- Base getCategories ready`);
                    // Kategóriák tárolása a reactive storage-ba.
                    this.appState.dispatch(new contextActions.SetCategoriesAction(categories));
                }
            },
            error: (e) => {
                console.error(e);
                this.isLoading = false;
                //this.toastError('Get categories failed.');
            },
            complete: () => this.isLoading = false
        });
    }

    protected getCompanyConfig() {

        const statetValue = this.state.getValue();
        if (!statetValue.companyConfigState) {

            console.log(`-- Base getCompanyConfig`);
            this.setCompanyConfig();
        }
    }

    protected setCompanyConfig() {

        this.isLoading = true;
        const companyUserEmail = this.authenticationService.companyUserEmail;
        this.companyService.getApiCompanyCompanyConfig(companyUserEmail).subscribe({
            next: (response: CompanyConfigDto) => {

                console.log(`-- Base getCompanyConfig ready`);
                this.appState.dispatch(new contextActions.SetCompanyConfigAction(response));
            },
            error: (e) => {
                console.error(e);
                this.isLoading = false;
                //this.toastError('Get company config failed.');
            },
            complete: () => this.isLoading = false
        });
    }

    protected getProductsRequest(): PageableRequest {
        return undefined;
    }

    protected getProducts() {

        this.isLoading = true;

        if (this.isCompanyAuthenticated) {

            const request: GetShopProductsCompanyRequest = this.getProductsRequest();
            request.companyUserEmail = this.authenticationService.companyUserEmail;

            this.shopProductCompanyService.postApiShopProductCompanyList(request).subscribe({

                next: (response: GetProductsResponse) => {
                    this.setProductsResponse(response);
                },
                error: (e) => {
                    console.error(e);
                    this.isLoading = false;
                },
                complete: () => this.isLoading = false
            });

        } else {

            const request = this.getProductsRequest();
            this.shopProductService.postApiShopProductList(request).subscribe({

                next: (response: GetProductsResponse) => {
                    this.setProductsResponse(response);
                },
                error: (e) => {
                    console.error(e);
                    this.isLoading = false;
                },
                complete: () => this.isLoading = false
            });
        }
    }

    private setProductsResponse(response: GetProductsResponse) {

        if (response?.products?.length) {

            this.products = response.products.map(p => this.mapperService.toProduct(p));
        } else {

            this.products = [];
        }
    }

    public changeCount(count: number) {
        this.count = count;
        this.getProducts();
    }

    public changeSorting(sort: SortItem) {
        this.sort = sort;
    }

    public changeViewType(viewType: string, viewCol: number) {
        this.viewType = viewType;
        this.viewCol = viewCol;
    }

    protected validateControls(group: FormGroup | FormArray | undefined): void {

        Object.keys(group!.controls).forEach((key: string) => {
            const abstractControl = group!.controls[key];

            if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
                this.validateControls(abstractControl);
            } else {
                abstractControl.markAsDirty();
                abstractControl.updateValueAndValidity();
            }
        });
    }
}
