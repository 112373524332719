<mat-card class="property-item p-0" [ngClass]="[viewType+'-item', 'column-'+column]" [class.full-width-page]="fullWidthPage" fxLayout="row wrap">

    <div fxFlex="100" fxShrink="3" [fxFlex.gt-sm]="(viewType=='list') ? 40 : 100" [fxFlex.sm]="(viewType=='list') ? 50 : 100" class="thumbnail-section product-item">

        <div class="image-container-wrapper">
            <div class="image-container selectable" 
                 (click)="onSelectProduct(product)">

                 <div *ngIf="product?.hasPackagingLabel"
                      class="packaging-name">{{product?.packagingName}}
                 </div>

                <img *ngIf="product?.image" class="img" [lazyLoad]="product | ProductImageResolver">
            </div> 
        </div>
        
        <a class="title text-truncate" 
           (click)="onSelectProduct(product)">
           
            <div class="prodcut-name-container" *ngIf="product?.businessName; else noBusinessName">
                <div class="flex justify-content-center align-items-center break-spaces font-bold">{{product?.businessName}}</div>
            </div>
            <ng-template #noBusinessName>
                <div class="flex justify-content-center align-items-center break-spaces font-bold">{{product?.name}}</div>
            </ng-template>            
        </a>
        
        <h2 class="fw-500 price">{{product?.newPrice | number : '.0-0' | currencySign: product?.currencyId}}</h2>
        
        <div class="icons">
            <app-controls [product]="product"></app-controls>
        </div>

    </div>

</mat-card>
