import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ProductBasePage } from 'src/app/pages/base/product-base-page';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ProductBasePage implements OnInit {

  isMobile = false;
  lat: number;
  lng: number;
  thisYear = new Date().getFullYear();
  version: string;
  zoom: number;

  constructor(
    public appService: AppService,
    public mediaObserver: MediaObserver) {
    super();

    this.lat = this.officeService.office.gpsCoord.lat;
    this.lng = this.officeService.office.gpsCoord.lng;
    this.version = this.officeService.office.version;
    this.zoom = this.officeService.office.zoom;
  }

  ngOnInit() {

    this.subscriptions.push(

      this.mediaObserver.asObservable()
        .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
        .subscribe((change: MediaChange) => {
          if (change.mqAlias == 'xs') {
            this.isMobile = true;

          } else {
            this.isMobile = false;
          }
        }),
    );
  }

  subscribe() { }
}
