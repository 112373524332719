import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NavigationGuardService } from './services/navigation-guard.service';
import { AppRouteLink } from './config/route-config';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '',                             loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: AppRouteLink.ACCOUNT_ID,        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),                        data: { breadcrumb: 'Account Settings' }, canActivate: [NavigationGuardService] },
            { path: 'brands',                       loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule),                           data: { breadcrumb: 'Brands' } },
            { path: 'cart',                         loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule),                                 data: { breadcrumb: 'Cart' } },
            { path: 'checkout',                     loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule),                     data: { breadcrumb: 'Checkout' } },
            { path: 'compare',                      loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule),                        data: { breadcrumb: 'Compare' } },
            { path: 'conditions',                   loadChildren: () => import('./pages/conditions/conditions.module').then(m => m.ConditionsModule),               data: { breadcrumb: 'Checkout' } },
            { path: 'contact',                      loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),                        data: { breadcrumb: 'Contact' } },
            { path: AppRouteLink.LOGIN_ID,          loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),                              data: { breadcrumb: 'Login' } },
            { path: 'search',                       loadChildren: () => import('./pages/search/search.module').then(m => m.ShearchModule),                          data: { breadcrumb: 'Search' } },
            { path: AppRouteLink.PASSWORD_SET_ID,   loadChildren: () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetModule),    data: { breadcrumb: 'Password reset' }, canActivate: [NavigationGuardService] },
            { path: 'products',                     loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),                     data: { breadcrumb: 'All Products' } },
            { path: 'sign-in',                      loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule),                         data: { breadcrumb: 'Sign In ' } },
            { path: 'wishlist',                     loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule),                     data: { breadcrumb: 'Wishlist' } },
            { path: 'registration',                 loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule),         data: { breadcrumb: 'Registration' } },
            { path: 'fiva',                         loadChildren: () => import('./pages/fiva/fiva.module').then(m => m.FivaModule),                                 data: { breadcrumb: 'Fiva' } },
        ]
    },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking', // for one load page, without reload
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }