import { AppService } from '../app.service';
import { HttpHeaders } from '@angular/common/http';
import { IAppState } from 'src/app/redux/app.state';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorage } from '../config/application.config';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as contextActions from '../redux/actions/context.actions';
import decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    /**
     * In the case of password reset, the identifier of the user defined by the temporary token.
     */
    resetUerName: string;

    public get authorizationCompanyToken(): string {
        return this.storageService.getLocalStorage(LocalStorage.COMPANY_TOKEN);
    }
    public set authorizationCompanyToken(value: string) {
        this.storeCompanyToken(value);
    }

    public get authorizationCustomerToken(): string {
        return this.storageService.getLocalStorage(LocalStorage.CUSTOMER_TOKEN);
    }
    public set authorizationCustomerToken(value: string) {
        this.storeCustomerToken(value);
    }

    private jwtHelper: JwtHelperService;

    constructor(
        public readonly appService: AppService,
        private appStore: Store<IAppState>,
        private router: Router,
        private storageService: LocalStorageService) { 

        this.jwtHelper = new JwtHelperService();
    }

    get hasCompanyToken(): boolean {
        return localStorage.getItem(LocalStorage.COMPANY_TOKEN) !== null;        
    }

    /**
     * Create request JSON Header.
     */
    get companyHeader(): HttpHeaders {

        return this.getCompanyHeaderByToken(this.authorizationCompanyToken);
    }

    public getCompanyHeaderByToken(authorizationAgentToken: string): HttpHeaders {

        return new HttpHeaders({
            'Access-Control-Allow-Origin': 'application/json',
            'Authorization': `Bearer ${authorizationAgentToken}`
        });
    }

    public get companyUserEmail(): string {

        return this.getCompanyUserEmailByToken(this.authorizationCompanyToken);
    }

    private getCompanyUserEmailByToken(authorizationCompanyToken: string): string {

        let result: string;

        if (authorizationCompanyToken) {

            const tokenPayload: any = decode(authorizationCompanyToken);
            result = tokenPayload.companyUserEmail || undefined;
        }

        return result;
    }

    public storeCompanyToken(token: string) {

        this.storageService.setLocalStorage(LocalStorage.COMPANY_TOKEN, token);
    }

    public removeCompanyToken() {

        this.storageService.removeLocalStorage(LocalStorage.COMPANY_TOKEN);
    }

    public storeCustomerToken(token: string) {

        this.storageService.setLocalStorage(LocalStorage.CUSTOMER_TOKEN, token);
    }

    public removeCustomerToken() {

        this.storageService.removeLocalStorage(LocalStorage.CUSTOMER_TOKEN);
    }

    /**
     * Check whether the agent token is expired and return true or false.
     */
    public get isCompanyAuthenticated(): boolean {

        return this.authorizationCompanyToken && !this.jwtHelper.isTokenExpired(this.authorizationCompanyToken);
    }

    /**
     * Check whether the customer token is expired and return true or false.
     */
    public get isCustomerAuthenticated(): boolean {

        return !this.jwtHelper.isTokenExpired(this.authorizationCustomerToken);
    }

    public get isAuthenticated(): boolean {

        return this.isCompanyAuthenticated || this.isCustomerAuthenticated;
    }

    clenupStorage() {

        this.appStore.dispatch(new contextActions.SetCompanyConfigAction(undefined));
        this.appStore.dispatch(new contextActions.SetCurrencyIdAction(undefined));        
        this.appStore.dispatch(new contextActions.SetProductBestsellersAction(undefined));
        this.appStore.dispatch(new contextActions.SetProductNewsAction(undefined));

        this.appService.Data.cartList.length = 0;
        this.appService.Data.totalCartCount = 0;
    }

    signIn(token: string) {
        this.authorizationCompanyToken = token;

        this.clenupStorage();
    }

    signOut() {
        this.removeCompanyToken();

        this.clenupStorage();

        this.router.navigate(['/login']);
    }
}
