/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetCompaniesResponse } from '../models/get-companies-response';
import { PageableRequest } from '../models/pageable-request';
import { CompanyDto } from '../models/company-dto';
import { CompanyConfigDto } from '../models/company-config-dto';
import { ImageDto } from '../models/image-dto';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly postApiCompanyListPath = '/api/Company/List';
  static readonly postApiCompanyPath = '/api/Company';
  static readonly putApiCompanyPath = '/api/Company';
  static readonly deleteApiCompanyPath = '/api/Company';
  static readonly getApiCompanyCompanyConfigPath = '/api/Company/CompanyConfig';
  static readonly postApiCompanyCompanyAvatarImagePath = '/api/Company/CompanyAvatarImage';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiCompanyListResponse(body?: PageableRequest): __Observable<__StrictHttpResponse<GetCompaniesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company/List`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetCompaniesResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiCompanyList(body?: PageableRequest): __Observable<GetCompaniesResponse> {
    return this.postApiCompanyListResponse(body).pipe(
      __map(_r => _r.body as GetCompaniesResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiCompanyResponse(body?: CompanyDto): __Observable<__StrictHttpResponse<CompanyDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiCompany(body?: CompanyDto): __Observable<CompanyDto> {
    return this.postApiCompanyResponse(body).pipe(
      __map(_r => _r.body as CompanyDto)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  putApiCompanyResponse(body?: CompanyDto): __Observable<__StrictHttpResponse<CompanyDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  putApiCompany(body?: CompanyDto): __Observable<CompanyDto> {
    return this.putApiCompanyResponse(body).pipe(
      __map(_r => _r.body as CompanyDto)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  deleteApiCompanyResponse(body?: CompanyDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  deleteApiCompany(body?: CompanyDto): __Observable<number> {
    return this.deleteApiCompanyResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param companyUserEmail undefined
   * @return Success
   */
  getApiCompanyCompanyConfigResponse(companyUserEmail?: string): __Observable<__StrictHttpResponse<CompanyConfigDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (companyUserEmail != null) __params = __params.set('companyUserEmail', companyUserEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/CompanyConfig`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyConfigDto>;
      })
    );
  }
  /**
   * @param companyUserEmail undefined
   * @return Success
   */
  getApiCompanyCompanyConfig(companyUserEmail?: string): __Observable<CompanyConfigDto> {
    return this.getApiCompanyCompanyConfigResponse(companyUserEmail).pipe(
      __map(_r => _r.body as CompanyConfigDto)
    );
  }

  /**
   * @param params The `CompanyService.PostApiCompanyCompanyAvatarImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `FolderId`:
   *
   * @return Success
   */
  postApiCompanyCompanyAvatarImageResponse(params: CompanyService.PostApiCompanyCompanyAvatarImageParams): __Observable<__StrictHttpResponse<ImageDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    if (params.FolderId != null) { __formData.append('FolderId', JSON.stringify(params.FolderId));}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company/CompanyAvatarImage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImageDto>;
      })
    );
  }
  /**
   * @param params The `CompanyService.PostApiCompanyCompanyAvatarImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `FolderId`:
   *
   * @return Success
   */
  postApiCompanyCompanyAvatarImage(params: CompanyService.PostApiCompanyCompanyAvatarImageParams): __Observable<ImageDto> {
    return this.postApiCompanyCompanyAvatarImageResponse(params).pipe(
      __map(_r => _r.body as ImageDto)
    );
  }
}

module CompanyService {

  /**
   * Parameters for postApiCompanyCompanyAvatarImage
   */
  export interface PostApiCompanyCompanyAvatarImageParams {
    File?: Blob;
    FolderId?: number;
  }
}

export { CompanyService }
