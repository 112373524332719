import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductBasePage } from 'src/app/pages/base/product-base-page';
import { Router } from '@angular/router';
import { SmartDialogTypeId } from 'src/app/config/application.config';

@Component({
    templateUrl: 'smart-dialog.html',
    styleUrls: ['./smart-dialog.scss']
})
export class SmartDialogComponent extends ProductBasePage {

    smartDialogTypeId = SmartDialogTypeId;

    dialogTypeId: number;
    subMessage: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<SmartDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public router: Router
    ) {
        super();

        if (data) {
            this.dialogTypeId = data.dialogTypeId;
            this.message = data.message;
            this.subMessage = data.subMessage;
        }
    }

    onTickClick(): void {

        this.dialogRef.close();
    }
}
