import { AuthenticationService } from './authentication.service';
import { IAppState } from 'src/app/redux/app.state';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ServiceLocator } from '../services/locator.service';
import { State } from '@ngrx/store';
import * as appStateSelectors from '../redux/app.state';

@Injectable({
    providedIn: 'root'
})
export class TokenGuardService {

    private state: State<IAppState>;

    private statetValue: appStateSelectors.IAppState;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private authenticationService: AuthenticationService) { }

    initialize() {

        if (!this.state) {
            this.state = ServiceLocator.injector.get(State<IAppState>);
            this.statetValue = this.state.getValue();
        }

        if (isPlatformBrowser(this.platformId)) {

            setInterval(() => {

                if (this.authenticationService.hasCompanyToken) {

                    if (!this.authenticationService.isAuthenticated) {
                        this.authenticationService.signOut();
                    }

                } else if (this.statetValue.companyConfigState) {
                    this.authenticationService.signOut();
                }

            }, 4000);
        }
    }
}
