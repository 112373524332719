<ng-container [ngSwitch]="dialogTypeId">

    <div *ngSwitchCase="smartDialogTypeId.Information" class="paid message-container">

        <div class="paid-inv message-inner-container">

            <div class="round paid-inv title-img-container" (click)="onTickClick()">

                <div class="round paid title-img-outer" matRipple>
                    <mat-icon aria-hidden="true" class="title-img">done</mat-icon>
                </div>

            </div>

            <div class="title-container">

                <div class="title-text-container">

                    <div class="title">Információ</div>
                    <div>{{message}}</div>

                </div>

                <div class="separator-paid">
                    <div class="left-hole paid"></div>
                    <div class="right-hole paid"></div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center" class="title-text-container">
                    {{subMessage}}
                </div>

            </div>

        </div>

    </div>
    
    <div *ngSwitchCase="smartDialogTypeId.Error">

        <div class="payment-failed message-container">
            <div class="payment-failed-inv message-inner-container">
        
                <div class="round payment-failed-inv title-img-container">
        
                    <div class="round payment-failed title-close-outer-error">
                        <mat-icon aria-hidden="true" class="title-img" (click)="onTickClick()">clear</mat-icon>
                    </div>
        
                </div>
        
                <div class="title-container">
        
                    <div class="title-text-container">
        
                        <div class="title">Hiba</div>
                        <div>{{message}}</div>
            
                    </div>
        
                    <div class="separator-payment-failed">
                        <div class="left-hole payment-failed"></div>
                        <div class="right-hole payment-failed"></div>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="center center" class="title-text-container">
                        {{subMessage}}
                    </div>
        
                    <div class="title-text-container mt-2">
                        <span class="mobile-title fa fa-mobile"></span>
                        <span class="subMessage" style="line-height: 43px"><a [href]="office?.officePhoneRef" style="color: inherit; text-decoration: none;"> {{office?.officePhone}}</a></span>
                    </div>
        
                </div>
        
            </div>
        </div>

    </div>
    
    <div *ngSwitchCase="smartDialogTypeId.Waring">

        <div class="payment-cancel message-container">
            <div class="payment-cancel-inv message-inner-container">
        
                <div class="round payment-cancel-inv title-img-container">
        
                    <div class="round payment-cancel title-close-outer-cancel">
                        <mat-icon aria-hidden="true" class="title-img" (click)="onTickClick()">clear</mat-icon>
                    </div>
        
                </div>
        
                <div class="title-container">
        
                    <div class="title-text-container canel">
        
                        <div class="title">Hiba</div>
                        <div>{{message}}</div>
        
                    </div>
        
                    <div class="separator-payment-cancel">
                        <div class="left-hole payment-cancel"></div>
                        <div class="right-hole payment-cancel"></div>
                    </div>
        
                    <div class="title-text-container canel">
        
                        <div class="subMessage">{{subMessage}}</div>

                    </div>
        
                </div>
        
            </div>
        </div>

    </div>

</ng-container>
