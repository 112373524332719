import { Pipe, PipeTransform } from '@angular/core';
import { parse, format, AsYouType, CountryCode, NumberFormat, NationalNumber } from 'libphonenumber-js';
import { CountryCodes } from 'src/app/config/phone.config';

@Pipe({ name: 'PhoneNumber' })
export class PhoneNumberPipe implements PipeTransform {

    public transform(value: string, countryCode: string): string {
        let result: string = value;
        if (value && countryCode) {

            let countryCodeObj = <CountryCode>countryCode.toUpperCase();
            if (countryCodeObj) {
                //result = new AsYouType(countryCodeObj).input(value);

                const country = <CountryCode>countryCode.toUpperCase();
                const numberFormat: any = 'INTERNATIONAL';
                const phone = <NationalNumber>value;
                result = format(phone, country, numberFormat);
            }
        }

        if (result) {

            const fragments = result.split(' ');

            if (fragments && fragments.length > 1) {

                if (fragments[0].startsWith('+')) {
                    fragments[0] = `(${fragments[0]})`;
                    result = fragments.join(' ');
                } else {

                    const dialingCode = <CountryCode>this.getCountryByDialingCode(fragments[0]);
                    if (dialingCode) {

                        const numberFormat: any = 'INTERNATIONAL';
                        const phone = <NationalNumber>fragments[1];
                        result = format(phone, dialingCode, numberFormat);
                    } else {

                        result = value;
                    }
                }
            }
        }

        return result;
    }

    private getCountryByDialingCode(dialingCode: string) {
        const country = CountryCodes.find(code => code.dialingCode === dialingCode);
        return country ? country.twoLetterCountryCode : undefined;
    }
}