import { Brand } from 'src/app/app.models';
import { Pipe, PipeTransform } from '@angular/core';
import { TypeResolverService } from 'src/app/services/type-resolver-service';

@Pipe({
  name: 'ImageResolver'
})
export class ImageResolverPipe implements PipeTransform {

  constructor(public typeResolverService: TypeResolverService) {
  }

  transform(imageUrl: string) {
    var result: string;

    if (imageUrl) {
      result = this.typeResolverService.resolveServiceUrl(imageUrl);
    }

    return result;
  }
}

