
<div class="top-banner-container" fxLayout="column">

    <div class="banner-back-container">
        <div class="banner-title-container">
            <h2 class="title">AUTÓKOZMETIKA</h2>
            <h2 class="title2">MESTERFOKON</h2>
        </div>    
    </div>

    <div class="banner-procucts-container">
    </div>

    <div class="banner-fiva-container">
        <a [routerLink]="['/fiva']">
            <img class="w-full" src="assets/images/fiva/FIVA_logo_small.png">
        </a>
    </div>

    <div fxShow="true" fxHide.gt-xs class="banner-registration-container">
        <div class="px-2" [routerLink]="['/registration']">Regisztráció!</div>
    </div>
    
</div>


<div class="banners-container" fxLayout="column" *ngIf="banners.length > 0">

    <div fxLayout="column" fxLayout.gt-sm="row" class="padding-bottom-fix">

        <div fxLayout="row" fxFlex="50" class="margin-right">

            <div class="banner margin-right-fix" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'cleaning'}">
                <div class="info info1" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container">
                            <div class="title cleaning">tisztítás</div>
                        </div>

                        <div class="banner-cleaning-image-container">
                            <img src="assets/images/banner/cleaning.webp" height = "100%" alt="user-image">
                        </div>

                        <div class="banner-cleaning-text-container">
                            Választékunk széles spektruma hatékony megoldásokat kínál mindenféle tisztítási feladathoz.
                        </div>

                        <div class="arrow-container">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                                <g class="cleaning">
                                    <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                    <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                    <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                    <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                                </g>
                            </svg>
                        </div>

                    </div> 
                </div>
            </div>

            <div class="banner" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'care'}">
                <div class="info info1" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container">
                            <div class="title care">ápolás</div>
                        </div>

                        <div class="banner-care-image-container">
                            <img src="assets/images/banner/care.webp" height = "100%" alt="user-image">
                        </div>

                        <div class="banner-care-text-container">
                            A GLOBO® sorozat termékei széleskörű ápolási megoldásokat nyújtanak mindenféle felületre.
                        </div>

                        <div class="arrow-container">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                                <g class="care">
                                    <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                    <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                    <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                    <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                                </g>
                            </svg>
                        </div>

                    </div> 
                </div> 
            </div>
        </div>

        <div fxLayout="row" fxFlex="50" class="padding-top" >
            <div class="banner margin-right-fix" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'protection'}">

                <div class="info info1" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container">
                            <div class="title protection">védelem</div>
                        </div>

                        <div class="banner-protection-image-container">
                            <img src="assets/images/banner/protection.webp" height = "100%" alt="user-image">
                        </div>

                        <div class="banner-protection-text-container">
                            Univerzális és speciális termékcsaláddal rendelkezünk.
                        </div>

                        <div class="arrow-container">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                                <g class="protection">
                                    <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                    <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                    <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                    <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                                </g>
                            </svg>
                        </div>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'accessories'}">

                <div class="info info1" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container">
                            <div class="title accessories">kiegészítők</div>
                        </div>

                        <div class="banner-accessories-image-container">
                            <img src="assets/images/banner/accessories.webp" height = "100%" alt="user-image">
                        </div>

                        <div class="banner-accessories-text-container">
                            A megfelelő kiegészítők használatával az ápolás sokkal hatékonyabb.
                        </div>

                        <div class="arrow-container">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                                <g class="accessories">
                                    <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                    <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                    <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                    <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                                </g>
                            </svg>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>

    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" class="padding-top-fix">

        <div fxLayout="row" fxFlex="50" class="margin-right">
            <div class="banner info1 margin-right-fix" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'rim-cleaning'}">
                <div class="info" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">

                        <div class="banner-title-container">
                            <div class="title rim-cleaning">Felni tisztítás</div>
                        </div>

                        <div class="banner-rim-cleaning-image-container">
                            <img src="assets/images/banner/rim-cleaning.webp" height = "100%" alt="user-image">
                        </div>

                        <div class="banner-rim-cleaning-text-container">
                            Rendkívül hatékony speciális tisztítószerek acél és könnyűfém felnihez.
                        </div>

                        <div class="arrow-container">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                                <g class="rim-cleaning">
                                    <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                    <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                    <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                    <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                                </g>
                            </svg>
                        </div>

                    </div> 
                </div> 
            </div>

            <div class="banner info1" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'polishing-material'}">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="banner-title-container">
                        <div class="title polishing-material">polir anyagok</div>
                    </div>

                    <div class="banner-polishing-material-image-container">
                        <img src="assets/images/banner/polishing-material.webp" height = "100%" alt="user-image">
                    </div>

                    <div class="banner-polishing-material-text-container">
                        Magas hatékonyságú polírozó anyagaink tökéletes fényt és megjelenést biztosítanak.
                    </div>

                    <div class="arrow-container">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                            <g class="polishing-material">
                                <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                            </g>
                        </svg>
                    </div>
                </div> 
            </div>
        </div>

        <div fxLayout="row" fxFlex="50" class="padding-bottom">

            <div class="banner margin-right-fix" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'waxes'}">

                <div class="info info1" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container">
                            <div class="title waxes">waxok</div>
                        </div>

                        <div class="banner-waxes-image-container">
                            <img src="assets/images/banner/waxes.webp" height = "100%" alt="user-image">
                        </div>

                        <div class="banner-waxes-text-container">
                            Wax termékeink garantálják a tartós védelmet és a gyönyörű megjelenést.
                        </div>

                        <div class="arrow-container">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                                <g class="waxes">
                                    <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                    <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                    <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                    <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                                </g>
                            </svg>
                        </div>
                    </div> 
                </div> 
            </div>

            <div class="banner" fxLayout="column" fxFlex="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'air-fresheners'}">

                <div class="info info1" fxFlex fxLayoutAlign="center center">
                    <div class="banner-title-container">
                        <div class="title air-fresheners">Illatosítók</div>
                    </div>

                    <div class="banner-air-fresheners-image-container">
                        <img src="assets/images/banner/air-fresheners.png" height = "100%" alt="user-image">
                    </div>

                    <div class="banner-air-fresheners-text-container">
                        Illatosítóink frissességet és stílust kölcsönöznek az utazásaihoz.
                    </div>

                    <div class="arrow-container">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
                            <g class="air-fresheners">
                                <polygon points="62.5,496.7 268.7,290.6 62.5,84.4 5.3,141.6 154.3,290.6 5.3,439.5"/>
                                <polygon points="253.2,496.7 459.4,290.6 253.2,84.4 196,141.6 345,290.6 196,439.5"/>
                                <polygon points="442.7,496.7 648.9,290.6 442.7,84.4 385.6,141.6 534.6,290.6 385.6,439.5"/>
                                <polygon points="632,496.7 838.2,290.6 632,84.4 574.8,141.6 723.8,290.6 574.8,439.5"/>
                            </g>
                        </svg>
                    </div>

                </div> 
            </div>
        </div>

    </div>

</div>











<!-- <div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">

    <div fxLayout="column" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="25" [routerLink]="['/products']" [queryParams]="{filterCategories: 'kitchen-utensils'}">

        <div class="banner" fxFlex="100" fxFlex.gt-sm="100" [ngStyle]="getBgImage(0)">
            <div class="info info0" fxLayoutAlign="start start">
                <div class="m-1" fxLayout="column" fxLayoutAlign="center center">
                    <div class="banner-title-container" style="top: 30px; left: 40px">
                        <h1 class="title">{{getBanner(0).title}}</h1>
                        <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    </div>
                </div> 
            </div> 
        </div>

    </div>

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'dishes'}">

        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="50">
            <div class="banner info1" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="67.77" [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="bottom: 20px; right: 40px">
                            <div class="title">{{getBanner(1).title}}</div>
                            <div class="subtitle">{{getBanner(1).subtitle}}</div>
                        </div>
                    </div> 
                </div> 
            </div>
            <div class="banner info1" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="33.33" [ngStyle]="getBgImage(2)" [routerLink]="['/products']" [queryParams]="{filterCategories: 'decoration'}">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="top: 20px; right: 30px">
                            <h2 class="title">{{getBanner(2).title}}</h2>
                            <div class="subtitle">{{getBanner(2).subtitle}}</div>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>

        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="50">
            <div class="banner" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="33.33" [ngStyle]="getBgImage(3)" [routerLink]="['/products']" [queryParams]="{filterCategories: 'kettle'}">

                <div class="info info1" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="top: 30px; left: 30px">
                            <h2 class="title">{{getBanner(3).title}}</h2>
                            <div class="subtitle">{{getBanner(3).subtitle}}</div>
                        </div>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="67.77" [ngStyle]="getBgImage(4)" [routerLink]="['/products']" [queryParams]="{filterCategories: 'glasses'}">

                <div class="info info1" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="bottom: 50px; left: 30px">
                            <div class="title">{{getBanner(4).title}}</div>
                            <div class="subtitle">{{getBanner(4).subtitle}}</div>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>

    </div>

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="25" [routerLink]="['/products']" [queryParams]="{filterCategories: 'thermos'}">

        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="100" fxFlex.gt-sm="100" [ngStyle]="getBgImage(5)">
            <div class="info info0" fxLayout="column" fxLayoutAlign="center end">
                <div class="m-1" fxLayout="column" fxLayoutAlign="center center">
                    <div class="banner-title-container" style="position: absolute; bottom: 35px; right: 33px">
                        <h1 class="title">{{getBanner(5).title}}</h1>
                        <h3 class="subtitle">{{getBanner(5).subtitle}}</h3>
                    </div>
                </div>
            </div> 
        </div>
    </div>

</div> -->

<!-- <div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">

        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                </div> 
            </div> 
        </div>

        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <div class="subtitle">{{getBanner(1).subtitle}}</div>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <div class="subtitle">{{getBanner(2).subtitle}}</div>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div> 
                </div> 
            </div>
        </div>

    </div>

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">

        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(3)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(3).title}}</h2>
                      <div class="subtitle">{{getBanner(3).subtitle}}</div>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div>                    
                </div>
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(4)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(4).title}}</h2>
                      <div class="subtitle">{{getBanner(4).subtitle}}</div>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div>
                </div> 
            </div>
        </div>

        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(5)">
            <div class="info" fxLayout="column" fxLayoutAlign="center end">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                  <h1 class="title">{{getBanner(5).title}}</h1>
                  <h3 class="subtitle">{{getBanner(5).subtitle}}</h3>
                  <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                </div>
            </div> 
        </div>
    </div>

</div> -->