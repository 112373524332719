import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let requestWithAuthHeader: HttpRequest<any> = undefined;
        if (this.authenticationService.isCompanyAuthenticated) {
            requestWithAuthHeader = request.clone(
                { setHeaders: { Authorization: `Bearer ${this.authenticationService.authorizationCompanyToken}` } });
        }

        request = requestWithAuthHeader ? requestWithAuthHeader : request;

        //this.spinner.show();

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                //this.spinner.hide();
            }
            return event;
        }),
            catchError((error: HttpErrorResponse) => {
                const started = Date.now();
                const elapsed = Date.now() - started;
                console.log(`Request for ${request.urlWithParams} failed after ${elapsed} ms.`);
                // debugger;
                return throwError(error);
            })
        );
    }
}
