/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseBase } from '../models/response-base';
import { LoginPasswordResetDto } from '../models/login-password-reset-dto';
import { SystemSettingDto } from '../models/system-setting-dto';
import { FileResponseBase } from '../models/file-response-base';
@Injectable({
  providedIn: 'root',
})
class ExternalUtilityService extends __BaseService {
  static readonly postExternalUtilityLoginPasswordResetPath = '/ExternalUtility/LoginPasswordReset';
  static readonly getExternalUtilitySystemSettingsPath = '/ExternalUtility/SystemSettings';
  static readonly getExternalUtilityComplaintPath = '/ExternalUtility/Complaint';
  static readonly getExternalUtilityCancellationNoticePath = '/ExternalUtility/CancellationNotice';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  postExternalUtilityLoginPasswordResetResponse(body?: LoginPasswordResetDto): __Observable<__StrictHttpResponse<ResponseBase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ExternalUtility/LoginPasswordReset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseBase>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postExternalUtilityLoginPasswordReset(body?: LoginPasswordResetDto): __Observable<ResponseBase> {
    return this.postExternalUtilityLoginPasswordResetResponse(body).pipe(
      __map(_r => _r.body as ResponseBase)
    );
  }

  /**
   * @return Success
   */
  getExternalUtilitySystemSettingsResponse(): __Observable<__StrictHttpResponse<Array<SystemSettingDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ExternalUtility/SystemSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystemSettingDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  getExternalUtilitySystemSettings(): __Observable<Array<SystemSettingDto>> {
    return this.getExternalUtilitySystemSettingsResponse().pipe(
      __map(_r => _r.body as Array<SystemSettingDto>)
    );
  }

  /**
   * @return Success
   */
  getExternalUtilityComplaintResponse(): __Observable<__StrictHttpResponse<FileResponseBase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ExternalUtility/Complaint`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileResponseBase>;
      })
    );
  }
  /**
   * @return Success
   */
  getExternalUtilityComplaint(): __Observable<FileResponseBase> {
    return this.getExternalUtilityComplaintResponse().pipe(
      __map(_r => _r.body as FileResponseBase)
    );
  }

  /**
   * @return Success
   */
  getExternalUtilityCancellationNoticeResponse(): __Observable<__StrictHttpResponse<FileResponseBase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ExternalUtility/CancellationNotice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileResponseBase>;
      })
    );
  }
  /**
   * @return Success
   */
  getExternalUtilityCancellationNotice(): __Observable<FileResponseBase> {
    return this.getExternalUtilityCancellationNoticeResponse().pipe(
      __map(_r => _r.body as FileResponseBase)
    );
  }
}

module ExternalUtilityService {
}

export { ExternalUtilityService }
