export const CountryCodes: Array<{ twoLetterCountryCode: string, dialingCode: string }> = [

    {twoLetterCountryCode: 'ES', dialingCode: '34'},
    {twoLetterCountryCode: 'GB', dialingCode: '44'},
    {twoLetterCountryCode: 'HU', dialingCode: '36'},
    {twoLetterCountryCode: 'DE', dialingCode: '49'},
    {twoLetterCountryCode: 'AF', dialingCode: '93'},
    {twoLetterCountryCode: 'AL', dialingCode: '355'},
    {twoLetterCountryCode: 'DZ', dialingCode: '213'},
    {twoLetterCountryCode: 'AS', dialingCode: '1-684'},
    {twoLetterCountryCode: 'AD', dialingCode: '376'},
    {twoLetterCountryCode: 'AO', dialingCode: '244'},
    {twoLetterCountryCode: 'AI', dialingCode: '1-264'},
    {twoLetterCountryCode: 'AQ', dialingCode: '672'},
    {twoLetterCountryCode: 'AG', dialingCode: '1-268'},
    {twoLetterCountryCode: 'AR', dialingCode: '54'},
    {twoLetterCountryCode: 'AM', dialingCode: '374'},
    {twoLetterCountryCode: 'AW', dialingCode: '297'},
    {twoLetterCountryCode: 'AU', dialingCode: '61'},
    {twoLetterCountryCode: 'AT', dialingCode: '43'},
    {twoLetterCountryCode: 'AZ', dialingCode: '994'},
    {twoLetterCountryCode: 'BS', dialingCode: '1-242'},
    {twoLetterCountryCode: 'BH', dialingCode: '973'},
    {twoLetterCountryCode: 'BD', dialingCode: '880'},
    {twoLetterCountryCode: 'BB', dialingCode: '1-246'},
    {twoLetterCountryCode: 'BY', dialingCode: '375'},
    {twoLetterCountryCode: 'BE', dialingCode: '32'},
    {twoLetterCountryCode: 'BZ', dialingCode: '501'},
    {twoLetterCountryCode: 'BJ', dialingCode: '229'},
    {twoLetterCountryCode: 'BM', dialingCode: '1-441'},
    {twoLetterCountryCode: 'BT', dialingCode: '975'},
    {twoLetterCountryCode: 'BO', dialingCode: '591'},
    {twoLetterCountryCode: 'BQ', dialingCode: '599'},
    {twoLetterCountryCode: 'BA', dialingCode: '387'},
    {twoLetterCountryCode: 'BW', dialingCode: '267'},
    {twoLetterCountryCode: 'BV', dialingCode: '47'},
    {twoLetterCountryCode: 'BR', dialingCode: '55'},
    {twoLetterCountryCode: 'IO', dialingCode: '246'},
    {twoLetterCountryCode: 'BN', dialingCode: '673'},
    {twoLetterCountryCode: 'BG', dialingCode: '359'},
    {twoLetterCountryCode: 'BF', dialingCode: '226'},
    {twoLetterCountryCode: 'BI', dialingCode: '257'},
    {twoLetterCountryCode: 'KH', dialingCode: '855'},
    {twoLetterCountryCode: 'CM', dialingCode: '237'},
    {twoLetterCountryCode: 'CA', dialingCode: '1'},
    {twoLetterCountryCode: 'CV', dialingCode: '238'},
    {twoLetterCountryCode: 'KY', dialingCode: '1-345'},
    {twoLetterCountryCode: 'CF', dialingCode: '236'},
    {twoLetterCountryCode: 'TD', dialingCode: '235'},
    {twoLetterCountryCode: 'CL', dialingCode: '56'},
    {twoLetterCountryCode: 'CN', dialingCode: '86'},
    {twoLetterCountryCode: 'CX', dialingCode: '61'},
    {twoLetterCountryCode: 'CC', dialingCode: '61'},
    {twoLetterCountryCode: 'CO', dialingCode: '57'},
    {twoLetterCountryCode: 'KM', dialingCode: '269'},
    {twoLetterCountryCode: 'CG', dialingCode: '242'},
    {twoLetterCountryCode: 'CD', dialingCode: '243'},
    {twoLetterCountryCode: 'CK', dialingCode: '682'},
    {twoLetterCountryCode: 'CR', dialingCode: '506'},
    {twoLetterCountryCode: 'HR', dialingCode: '385'},
    {twoLetterCountryCode: 'CU', dialingCode: '53'},
    {twoLetterCountryCode: 'CW', dialingCode: '599'},
    {twoLetterCountryCode: 'CY', dialingCode: '357'},
    {twoLetterCountryCode: 'CZ', dialingCode: '420'},
    {twoLetterCountryCode: 'CI', dialingCode: '225'},
    {twoLetterCountryCode: 'DK', dialingCode: '45'},
    {twoLetterCountryCode: 'DJ', dialingCode: '253'},
    {twoLetterCountryCode: 'DM', dialingCode: '1-767'},
    {twoLetterCountryCode: 'DO', dialingCode: '1-809'},
    {twoLetterCountryCode: 'EC', dialingCode: '593'},
    {twoLetterCountryCode: 'EG', dialingCode: '20'},
    {twoLetterCountryCode: 'SV', dialingCode: '503'},
    {twoLetterCountryCode: 'GQ', dialingCode: '240'},
    {twoLetterCountryCode: 'ER', dialingCode: '291'},
    {twoLetterCountryCode: 'EE', dialingCode: '372'},
    {twoLetterCountryCode: 'ET', dialingCode: '251'},
    {twoLetterCountryCode: 'FK', dialingCode: '500'},
    {twoLetterCountryCode: 'FO', dialingCode: '298'},
    {twoLetterCountryCode: 'FJ', dialingCode: '679'},
    {twoLetterCountryCode: 'FI', dialingCode: '358'},
    {twoLetterCountryCode: 'FR', dialingCode: '33 '},
    {twoLetterCountryCode: 'GF', dialingCode: '594'},
    {twoLetterCountryCode: 'PF', dialingCode: '689'},
    {twoLetterCountryCode: 'TF', dialingCode: '262'},
    {twoLetterCountryCode: 'GA', dialingCode: '241'},
    {twoLetterCountryCode: 'GM', dialingCode: '220'},
    {twoLetterCountryCode: 'GE', dialingCode: '995'},
    {twoLetterCountryCode: 'GH', dialingCode: '233'},
    {twoLetterCountryCode: 'GI', dialingCode: '350'},
    {twoLetterCountryCode: 'GR', dialingCode: '30'},
    {twoLetterCountryCode: 'GL', dialingCode: '299'},
    {twoLetterCountryCode: 'GD', dialingCode: '1-473'},
    {twoLetterCountryCode: 'GP', dialingCode: '590'},
    {twoLetterCountryCode: 'GU', dialingCode: '1-671'},
    {twoLetterCountryCode: 'GT', dialingCode: '502'},
    {twoLetterCountryCode: 'GG', dialingCode: '44'},
    {twoLetterCountryCode: 'GN', dialingCode: '224'},
    {twoLetterCountryCode: 'GW', dialingCode: '245'},
    {twoLetterCountryCode: 'GY', dialingCode: '592'},
    {twoLetterCountryCode: 'HT', dialingCode: '509'},
    {twoLetterCountryCode: 'HM', dialingCode: '672'},
    {twoLetterCountryCode: 'VA', dialingCode: '379'},
    {twoLetterCountryCode: 'HN', dialingCode: '504'},
    {twoLetterCountryCode: 'HK', dialingCode: '852'},
    {twoLetterCountryCode: 'IS', dialingCode: '354'},
    {twoLetterCountryCode: 'IN', dialingCode: '91'},
    {twoLetterCountryCode: 'ID', dialingCode: '62'},
    {twoLetterCountryCode: 'IR', dialingCode: '98'},
    {twoLetterCountryCode: 'IQ', dialingCode: '964'},
    {twoLetterCountryCode: 'IE', dialingCode: '353'},
    {twoLetterCountryCode: 'IM', dialingCode: '44'},
    {twoLetterCountryCode: 'IL', dialingCode: '972'},
    {twoLetterCountryCode: 'IT', dialingCode: '39'},
    {twoLetterCountryCode: 'JM', dialingCode: '1-876'},
    {twoLetterCountryCode: 'JP', dialingCode: '81'},
    {twoLetterCountryCode: 'JE', dialingCode: '44'},
    {twoLetterCountryCode: 'JO', dialingCode: '962'},
    {twoLetterCountryCode: 'KZ', dialingCode: '7'},
    {twoLetterCountryCode: 'KE', dialingCode: '254'},
    {twoLetterCountryCode: 'KI', dialingCode: '686'},
    {twoLetterCountryCode: 'KP', dialingCode: '850'},
    {twoLetterCountryCode: 'KR', dialingCode: '82'},
    {twoLetterCountryCode: 'KW', dialingCode: '965'},
    {twoLetterCountryCode: 'KG', dialingCode: '996'},
    {twoLetterCountryCode: 'LA', dialingCode: '856'},
    {twoLetterCountryCode: 'LV', dialingCode: '371'},
    {twoLetterCountryCode: 'LB', dialingCode: '961'},
    {twoLetterCountryCode: 'LS', dialingCode: '266'},
    {twoLetterCountryCode: 'LR', dialingCode: '231'},
    {twoLetterCountryCode: 'LY', dialingCode: '218'},
    {twoLetterCountryCode: 'LI', dialingCode: '423'},
    {twoLetterCountryCode: 'LT', dialingCode: '370'},
    {twoLetterCountryCode: 'LU', dialingCode: '352'},
    {twoLetterCountryCode: 'MO', dialingCode: '853'},
    {twoLetterCountryCode: 'MK', dialingCode: '389'},
    {twoLetterCountryCode: 'MG', dialingCode: '261'},
    {twoLetterCountryCode: 'MW', dialingCode: '265'},
    {twoLetterCountryCode: 'MY', dialingCode: '60'},
    {twoLetterCountryCode: 'MV', dialingCode: '960'},
    {twoLetterCountryCode: 'ML', dialingCode: '223'},
    {twoLetterCountryCode: 'MT', dialingCode: '356'},
    {twoLetterCountryCode: 'MH', dialingCode: '692'},
    {twoLetterCountryCode: 'MQ', dialingCode: '596'},
    {twoLetterCountryCode: 'MR', dialingCode: '222'},
    {twoLetterCountryCode: 'MU', dialingCode: '230'},
    {twoLetterCountryCode: 'YT', dialingCode: '262'},
    {twoLetterCountryCode: 'MX', dialingCode: '52'},
    {twoLetterCountryCode: 'FM', dialingCode: '691'},
    {twoLetterCountryCode: 'MD', dialingCode: '373'},
    {twoLetterCountryCode: 'MC', dialingCode: '377'},
    {twoLetterCountryCode: 'MN', dialingCode: '976'},
    {twoLetterCountryCode: 'ME', dialingCode: '382'},
    {twoLetterCountryCode: 'MS', dialingCode: '1-664'},
    {twoLetterCountryCode: 'MA', dialingCode: '212'},
    {twoLetterCountryCode: 'MZ', dialingCode: '258'},
    {twoLetterCountryCode: 'MM', dialingCode: '95'},
    {twoLetterCountryCode: 'NA', dialingCode: '264'},
    {twoLetterCountryCode: 'NR', dialingCode: '674'},
    {twoLetterCountryCode: 'NP', dialingCode: '977'},
    {twoLetterCountryCode: 'NL', dialingCode: '31'},
    {twoLetterCountryCode: 'NC', dialingCode: '687'},
    {twoLetterCountryCode: 'NZ', dialingCode: '64'},
    {twoLetterCountryCode: 'NI', dialingCode: '505'},
    {twoLetterCountryCode: 'NE', dialingCode: '227'},
    {twoLetterCountryCode: 'NG', dialingCode: '234'},
    {twoLetterCountryCode: 'NU', dialingCode: '683'},
    {twoLetterCountryCode: 'NF', dialingCode: '672'},
    {twoLetterCountryCode: 'MP', dialingCode: '1-670'},
    {twoLetterCountryCode: 'NO', dialingCode: '47'},
    {twoLetterCountryCode: 'OM', dialingCode: '968'},
    {twoLetterCountryCode: 'PK', dialingCode: '92'},
    {twoLetterCountryCode: 'PW', dialingCode: '680'},
    {twoLetterCountryCode: 'PS', dialingCode: '970'},
    {twoLetterCountryCode: 'PA', dialingCode: '507'},
    {twoLetterCountryCode: 'PG', dialingCode: '675'},
    {twoLetterCountryCode: 'PY', dialingCode: '595'},
    {twoLetterCountryCode: 'PE', dialingCode: '51'},
    {twoLetterCountryCode: 'PH', dialingCode: '63'},
    {twoLetterCountryCode: 'PN', dialingCode: '870'},
    {twoLetterCountryCode: 'PL', dialingCode: '48'},
    {twoLetterCountryCode: 'PT', dialingCode: '351'},
    {twoLetterCountryCode: 'PR', dialingCode: '1'},
    {twoLetterCountryCode: 'QA', dialingCode: '974'},
    {twoLetterCountryCode: 'RO', dialingCode: '40'},
    {twoLetterCountryCode: 'RU', dialingCode: '7'},
    {twoLetterCountryCode: 'RW', dialingCode: '250'},
    {twoLetterCountryCode: 'RE', dialingCode: '262'},
    {twoLetterCountryCode: 'BL', dialingCode: '590'},
    {twoLetterCountryCode: 'SH', dialingCode: '290'},
    {twoLetterCountryCode: 'KN', dialingCode: '1-869'},
    {twoLetterCountryCode: 'LC', dialingCode: '1-758'},
    {twoLetterCountryCode: 'MF', dialingCode: '590'},
    {twoLetterCountryCode: 'PM', dialingCode: '508'},
    {twoLetterCountryCode: 'VC', dialingCode: '1-784'},
    {twoLetterCountryCode: 'WS', dialingCode: '685'},
    {twoLetterCountryCode: 'SM', dialingCode: '378'},
    {twoLetterCountryCode: 'ST', dialingCode: '239'},
    {twoLetterCountryCode: 'SA', dialingCode: '966'},
    {twoLetterCountryCode: 'SN', dialingCode: '221'},
    {twoLetterCountryCode: 'RS', dialingCode: '381'},
    {twoLetterCountryCode: 'SC', dialingCode: '248'},
    {twoLetterCountryCode: 'SL', dialingCode: '232'},
    {twoLetterCountryCode: 'SG', dialingCode: '65'},
    {twoLetterCountryCode: 'SX', dialingCode: '1-721'},
    {twoLetterCountryCode: 'SK', dialingCode: '421'},
    {twoLetterCountryCode: 'SI', dialingCode: '386'},
    {twoLetterCountryCode: 'SB', dialingCode: '677'},
    {twoLetterCountryCode: 'SO', dialingCode: '252'},
    {twoLetterCountryCode: 'ZA', dialingCode: '27'},
    {twoLetterCountryCode: 'GS', dialingCode: '500'},
    {twoLetterCountryCode: 'SS', dialingCode: '211'},
    {twoLetterCountryCode: 'LK', dialingCode: '94'},
    {twoLetterCountryCode: 'SD', dialingCode: '249'},
    {twoLetterCountryCode: 'SR', dialingCode: '597'},
    {twoLetterCountryCode: 'SJ', dialingCode: '47'},
    {twoLetterCountryCode: 'SZ', dialingCode: '268'},
    {twoLetterCountryCode: 'SE', dialingCode: '46'},
    {twoLetterCountryCode: 'CH', dialingCode: '41'},
    {twoLetterCountryCode: 'SY', dialingCode: '963'},
    {twoLetterCountryCode: 'TW', dialingCode: '886'},
    {twoLetterCountryCode: 'TJ', dialingCode: '992'},
    {twoLetterCountryCode: 'TZ', dialingCode: '255'},
    {twoLetterCountryCode: 'TH', dialingCode: '66'},
    {twoLetterCountryCode: 'TL', dialingCode: '670'},
    {twoLetterCountryCode: 'TG', dialingCode: '228'},
    {twoLetterCountryCode: 'TK', dialingCode: '690'},
    {twoLetterCountryCode: 'TO', dialingCode: '676'},
    {twoLetterCountryCode: 'TT', dialingCode: '1-868'},
    {twoLetterCountryCode: 'TN', dialingCode: '216'},
    {twoLetterCountryCode: 'TR', dialingCode: '90'},
    {twoLetterCountryCode: 'TM', dialingCode: '993'},
    {twoLetterCountryCode: 'TC', dialingCode: '1-649'},
    {twoLetterCountryCode: 'TV', dialingCode: '688'},
    {twoLetterCountryCode: 'UG', dialingCode: '256'},
    {twoLetterCountryCode: 'UA', dialingCode: '380'},
    {twoLetterCountryCode: 'AE', dialingCode: '971'},
    {twoLetterCountryCode: 'US', dialingCode: '1'},
    {twoLetterCountryCode: 'UM', dialingCode: '1'},
    {twoLetterCountryCode: 'UY', dialingCode: '598'},
    {twoLetterCountryCode: 'UZ', dialingCode: '998'},
    {twoLetterCountryCode: 'VU', dialingCode: '678'},
    {twoLetterCountryCode: 'VE', dialingCode: '58'},
    {twoLetterCountryCode: 'VN', dialingCode: '84'},
    {twoLetterCountryCode: 'VG', dialingCode: '1-284'},
    {twoLetterCountryCode: 'VI', dialingCode: '1-340'},
    {twoLetterCountryCode: 'WF', dialingCode: '681'},
    {twoLetterCountryCode: 'EH', dialingCode: '212'},
    {twoLetterCountryCode: 'YE', dialingCode: '967'},
    {twoLetterCountryCode: 'ZM', dialingCode: '260'},
    {twoLetterCountryCode: 'ZW', dialingCode: '263'},
];
