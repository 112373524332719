import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'PhoneRef'
})
export class PhoneRefPipe implements PipeTransform {

    transform(phone: string) {

        let result = phone;
        if (phone.startsWith('+')) {
            const cleanPhone = this.getCleanPhoneNumber(phone);
            result = `tel:${cleanPhone}`;
        }

        return result;
    }

    private getCleanPhoneNumber(phone: string): string {

        if (phone) {
            phone = '' + phone.trim();
            phone = phone.replace(/\s\s+/g, ' ');
            phone = phone.split('–').join('');
            phone = phone.split('-').join('');
            phone = phone.split(' ').join('');
        }
        return phone ? phone : '';
    }
}
